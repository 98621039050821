export const light = {
  primaryText: 'hsl(268, 69%, 0%)',
  secondaryText: '#AAA',
  whiteText: '#FFF',
  primaryColor: 'hsl(268, 69%, 50%)',
  primary50: 'hsl(268, 69%, 20%)',
  primary100: 'hsl(268, 69%, 30%)',
  primary200: 'hsl(268, 69%, 40%)',
  primary600: 'hsl(268, 69%, 60%)',
  primary700: 'hsl(268, 69%, 70%)',
  primary800: 'hsl(268, 69%, 80%)',
  primary900: 'hsl(268, 69%, 96%)',
  primaryLight: 'hsl(268, 69%, 100%)',
  primaryDark: 'hsl(268, 69%, 0%)',
  success: 'hsl(88, 69%, 50%)',
  danger: 'hsl(328, 69%, 50%)',
  warning: 'hsl(28, 69%, 50%)',
  secondaryColor: 'hsl(208, 69%, 50%)',
  background: 'hsl(268, 69%, 100%)',
}

export const dark = {
  primaryText: 'hsl(268, 69%, 100%)',
  secondaryText: '#AAA',
  whiteText: '#FFF',
  primaryColor: 'hsl(268, 69%, 50%)',
  primary50: 'hsl(268, 69%, 20%)',
  primary100: 'hsl(268, 69%, 30%)',
  primary200: 'hsl(268, 69%, 40%)',
  primary600: 'hsl(268, 69%, 60%)',
  primary700: 'hsl(268, 69%, 70%)',
  primary800: 'hsl(268, 69%, 80%)',
  primary900: 'hsl(268, 69%, 96%)',
  primaryLight: 'hsl(268, 69%, 100%)',
  primaryDark: 'hsl(268, 69%, 0%)',
  background: 'hsl(268, 69%, 0%)',
  success: 'hsl(88, 69%, 50%)',
  danger: 'hsl(328, 69%, 50%)',
  warning: 'hsl(28, 69%, 50%)',
  secondaryColor: 'hsl(208, 69%, 50%)',
}
