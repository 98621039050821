import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.theme.primaryLight};
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100vw;
  @media (max-width: 1000px) {
    margin-bottom: 0.5rem;
    & .desktop-button {
      display: none;
    }
    & .mobile-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100vw;
      background-color: yellow;
    }
  }
`

const MobileRow = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${(props) => props.theme.primaryLight};
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    width: 100vw;
  }
`

const PrimaryButton = styled.button`
  outline: none;
  border: 1px solid ${(props) => props.theme.primaryColor};
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryLight};
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 0.25rem 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  box-shadow: 0px 1px 3px 2px ${(props) => props.theme.primary700};
  display: flex;
  align-items: center;
  justify-content: space-around;

  :hover {
    opacity: 0.8;
  }
  & i {
    margin-right: 1rem;
  }
`

const PrimaryButtonA = styled(NavLink)`
  text-decoration: none;
  outline: none;
  border: 1px solid ${(props) => props.theme.primaryColor};
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryLight};
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 0.25rem 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  box-shadow: 0px 1px 3px 2px ${(props) => props.theme.primary700};
  display: flex;
  align-items: center;
  justify-content: space-around;
  :hover {
    opacity: 0.8;
  }
  & i {
    margin-right: 1rem;
  }
`

const Menu = (props) => {
  const {
    pieces,
    addRow,
    removeRow,
    toggleOptions,
    displayOptions,
    startOver,
    displayResult,
    toggleResult,
  } = props

  const justTesting = (e) => {
    e.preventDefault()
    toggleResult(!displayResult)
  }
  return (
    <>
      <ButtonRow>
        <PrimaryButton onClick={() => startOver()}>
          <i className='far fa-times'></i>
          Clear
        </PrimaryButton>

        {displayResult ? (
          <PrimaryButton onClick={justTesting}>
            <i className='far fa-pencil'></i> Modify
          </PrimaryButton>
        ) : (
          <>
            {pieces.length > 1 ? (
              <PrimaryButton
                className='desktop-button'
                onClick={() => removeRow()}
              >
                <i className='far fa-minus'></i> Remove Row
              </PrimaryButton>
            ) : null}
            <PrimaryButton className='desktop-button' onClick={() => addRow()}>
              <i className='far fa-plus'></i>
              Add Row
            </PrimaryButton>
            <PrimaryButton onClick={() => toggleOptions(!displayOptions)}>
              {displayOptions ? (
                <i className='far fa-toggle-on'></i>
              ) : (
                <i className='far fa-toggle-off'></i>
              )}
              Options
            </PrimaryButton>
          </>
        )}
        <PrimaryButtonA to='/settings'>
          <i className='far fa-cogs'></i>
          Settings
        </PrimaryButtonA>
      </ButtonRow>
      <MobileRow>
        {pieces.length > 1 ? (
          <PrimaryButton onClick={() => removeRow()}>
            <i className='far fa-minus'></i> Remove Row
          </PrimaryButton>
        ) : null}
        <PrimaryButton onClick={() => addRow()}>
          <i className='far fa-plus'></i>
          Add Row
        </PrimaryButton>
      </MobileRow>
    </>
  )
}

export default Menu
