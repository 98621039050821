import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import Logo from '#components/Logo/'
import * as theme from '#root/theme'

const TitleRow = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid ${(props) => props.theme.primaryColor};
  box-shadow: 0px 5px 10px 2px ${(props) => props.theme.primaryColor};
  background-color: ${(props) => props.theme.primaryColor};
  & div {
    color: ${(props) => props.theme.primaryLight};
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
  }
  & div > span:last-of-type {
    font-size: 1.1rem;
    text-align: right;
  }
`

const Header = () => {
  return (
    <TitleRow>
      <Logo f={theme.light.primaryLight} h={80} w={190} />
    </TitleRow>
  )
}

export default Header
