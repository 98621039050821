import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import fetch from 'isomorphic-unfetch'

import Container from '#components/Container/'
import Header from '#components/Header/'
import Menu from '#components/Menu/'
import Options from '#components/Options/'
import FormSection from '#components/FormSection/'
import ResultArea from '#components/ResultArea/'

import DoTheMath from '#utils/DoTheMath'

const TheFooter = styled.div`
  width: 100vw;
  height: 120px;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryLight};
  font-family: 'Poppins', sans-serif;
  & .subscribe-container {
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & .subscribe-form-container {
    display: flex;
    align-items: center;
  }
  & .subscribe-form-container-textbox {
    font-size: 1.1rem;
  }
  & .subscribe-form-container button {
    margin-left: 0.5rem;
    outline: none;
    border: 0;
    border: 1px solid ${(props) => props.theme.primaryLight};
    background-color: ${(props) => props.theme.primaryColor};
    padding: 0.25rem 1rem;
    color: ${(props) => props.theme.primaryLight};
  }
  & .footer-bottom {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
  }
  & .footer-bottom a {
    color: ${(props) => props.theme.primaryLight};
    margin-right: 0.25rem;
  }
  @media (max-width: 1000px) {
    padding: 0.25rem;
    & .subscribe-container {
      margin: 0.25rem 0;
    }
    & .subscribe-text {
      margin-bottom: 0.25rem;
      width: 100%;
      text-align: center;
    }
  }
`

const Home = () => {
  const [dimFrom, setDimFrom] = useState('cm')
  const [wgtFrom, setWgtFrom] = useState('kg')
  const [dimTo, setDimTo] = useState('in')
  const [wgtTo, setWgtTo] = useState('lb')
  const [displayOptions, toggleOptions] = useState(true)
  const [displayResult, toggleResult] = useState(false)
  const [piecesResult, setPiecesResult] = useState(null)
  const [curPieces, setCurPieces] = useState(null)
  const [mailingInfo, setMailingInfo] = useState(null)
  const [mailLoading, setMailLoading] = useState(false)
  const [mailSent, setMailSent] = useState(false)

  const [pieces, setPieces] = useState([
    {
      quantity: 0,
      weight: 0,
      dimL: 0,
      dimW: 0,
      dimH: 0,
    },
  ])

  useEffect(() => {
    if (!localStorage.settings) {
      localStorage.settings = JSON.stringify({
        dimFrom: 'in',
        wgtFrom: 'lb',
        dimTo: 'in',
        wgtTo: 'lb',
        showOptions: true,
      })
    }

    let theSettings = JSON.parse(localStorage.settings)

    setDimFrom(theSettings.dimFrom)
    setWgtFrom(theSettings.wgtFrom)
    setDimTo(theSettings.dimTo)
    setWgtTo(theSettings.wgtTo)
    toggleOptions(theSettings.showOptions)
  }, [])

  const addRow = async () => {
    // alert('Fired')
    await setPieces([
      ...pieces,
      {
        quantity: 0,
        weight: 0,
        dimL: 0,
        dimW: 0,
        dimH: 0,
      },
    ])
  }

  const removeRow = async () => {
    let newArr = [...pieces]
    let lastId = pieces.length - 1
    // delete newArr[lastId];
    if (lastId > 0) {
      newArr.pop()
      await setPieces(newArr)
    }
  }

  const handleRowChange = async (e) => {
    if (e.target.value !== '' || e.target.value !== 'undefined') {
      // alert(e.target.value)
      let rowCount = +e.target.value
      let pieceCount = pieces.length
      let curPieces = pieces

      if (rowCount > pieceCount) {
        let diff = rowCount - pieceCount
        // alert(diff)
        let finalArr = []
        for (let y = 0; y < diff; y++) {
          let tmpObj = {
            quantity: 0,
            weight: 0,
            dimL: 0,
            dimW: 0,
            dimH: 0,
          }
          finalArr = [...finalArr, tmpObj]
        }
        setPieces(curPieces.concat(finalArr))
      }

      if (rowCount < pieceCount) {
        let diff = pieceCount - rowCount
        diff = diff * 1
        let tmpPieces = pieces
        setPieces(tmpPieces.splice(diff))
      }

      // setPieces()
    }
  }

  const startOver = async () => {
    window.location.reload(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    let result = DoTheMath(pieces, dimFrom, dimTo, wgtFrom, wgtTo)

    setPiecesResult(result)
    toggleResult(true)
    toggleOptions(false)
  }

  const handleMailingSubmit = async (e) => {
    e.preventDefault()
    setMailLoading(true)

    let req = {
      email: mailingInfo,
      utm_medium: 'web',
      utm_source: 'kalculator-com',
    }

    // alert(JSON.stringify(req))

    let url = 'https://api.kascotech.com/marketing-user/update'

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'x-auth-token': 'zyx',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })

    if (res.status === 200) {
      setMailSent(true)
    }

    setMailLoading(false)
  }

  const handleMailingTextChange = async (e) => {
    setMailingInfo(e.target.value)
  }

  const handleTextChange = async (e, key) => {
    let curPR = pieces

    curPR[key] = {
      ...curPR[key],
      [e.target.name]: e.target.value,
    }
    await setPieces(curPR)
  }

  return (
    <>
      <Container>
        <Header />
        <Menu
          pieces={pieces}
          addRow={addRow}
          removeRow={removeRow}
          toggleOptions={toggleOptions}
          displayOptions={displayOptions}
          startOver={startOver}
          displayResult={displayResult}
          toggleResult={toggleResult}
        />
        {!displayOptions ? null : (
          <Options
            pieces={pieces}
            addRow={addRow}
            removeRow={removeRow}
            toggleOptions={toggleOptions}
            displayOptions={displayOptions}
            startOver={startOver}
            setDimFrom={setDimFrom}
            setWgtFrom={setWgtFrom}
            setDimTo={setDimTo}
            setWgtTo={setWgtTo}
            dimFrom={dimFrom}
            dimTo={dimTo}
            wgtFrom={wgtFrom}
            wgtTo={wgtTo}
            handleRowChange={handleRowChange}
          />
        )}
        {displayResult ? (
          <ResultArea
            pieces={pieces}
            addRow={addRow}
            removeRow={removeRow}
            toggleOptions={toggleOptions}
            displayOptions={displayOptions}
            startOver={startOver}
            setDimFrom={setDimFrom}
            setWgtFrom={setWgtFrom}
            setDimTo={setDimTo}
            setWgtTo={setWgtTo}
            dimFrom={dimFrom}
            dimTo={dimTo}
            wgtFrom={wgtFrom}
            wgtTo={wgtTo}
            handleRowChange={handleRowChange}
            handleTextChange={handleTextChange}
            handleSubmit={handleSubmit}
            piecesResult={piecesResult}
          />
        ) : (
          <FormSection
            pieces={pieces}
            addRow={addRow}
            removeRow={removeRow}
            toggleOptions={toggleOptions}
            displayOptions={displayOptions}
            startOver={startOver}
            setDimFrom={setDimFrom}
            setWgtFrom={setWgtFrom}
            setDimTo={setDimTo}
            setWgtTo={setWgtTo}
            dimFrom={dimFrom}
            dimTo={dimTo}
            wgtFrom={wgtFrom}
            wgtTo={wgtTo}
            handleRowChange={handleRowChange}
            handleTextChange={handleTextChange}
            handleSubmit={(e) => handleSubmit(e)}
          />
        )}
      </Container>
      <TheFooter>
        <div className='subscribe-container'>
          <div className='subscribe-text'>
            Join our mailing list to learn about our other products
          </div>

          {!mailSent ? (
            <form
              onSubmit={handleMailingSubmit}
              className='subscribe-form-container'
            >
              <input
                type='email'
                className='subscribe-form-container-textbox'
                placeholder='E-Mail'
                onChange={handleMailingTextChange}
                required
              />
              {mailLoading ? null : (
                <button type='submit' onClick={(e) => handleMailingSubmit(e)}>
                  Submit
                </button>
              )}
            </form>
          ) : (
            <div className='subscribe-container'>
              <div className='subscribe-text'>Thank you for joining!</div>
            </div>
          )}
        </div>
        <div className='footer-bottom'>
          <a href='https://www.kascotech.com/?utm_source=kalculator-com&utm_medium=web'>
            Visit kascoTech.com for more information
          </a>
        </div>
      </TheFooter>
    </>
  )
}

export default Home
