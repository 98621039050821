import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import Container from '#components/Container'
import Header from '#components/Header'

const SettingsContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 100%;
    background-color: ${(props) => props.theme.primary900};
  }
  & .settings-form-container {
    margin-top: 1.2rem;
    min-height: 300px;
    width: calc(100% - 1rem - 4px);
    border: 2px solid ${(props) => props.theme.primaryColor};
    background-color: ${(props) => props.theme.primary900};
    color: ${(props) => props.theme.primaryColor};
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    @media (max-width: 1000px) {
      border: 0;
      margin-top: 0;
    }
  }
  & .settings-title {
    font-size: 3rem;
    padding: 0.5rem;
  }
  & .button-row {
    width: calc(100% - 1rem);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .settings-section {
    margin-top: 1rem;
  }
  & .settings-group {
    display: flex;
    align-items: center;
    border-bottom: 2px solid ${(props) => props.theme.primaryColor};
  }
  & .settings-group:last-of-type {
    border-bottom: 0;
  }
  & .settings-group-label {
    flex: 1;
    color: ${(props) => props.theme.primaryText};
    padding: 0.5rem;
  }
  & .settings-group-input {
    flex: 1;
    padding: 0.5rem;
    text-align: right;
  }
  & .settings-select {
    width: 80%;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
  }
`

const BackButton = styled(NavLink)`
  text-decoration: none;
  padding: 0.25rem 1rem;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryLight};
  font-size: 1rem;
  border-radius: 5px;
  & i {
    margin-right: 2rem;
  }
`

const Settings = () => {
  const [settings, setSettings] = useState(null)

  useEffect(() => {
    if (localStorage.settings) {
      if (settings === null) {
        setSettings(JSON.parse(localStorage.settings))
      }
    }
  }, [checkForSettings])

  const checkForSettings = () => {
    if (localStorage.settings) {
      return true
    } else {
      return false
    }
  }

  const changeDimFrom = (e) => {
    let prvSettings = JSON.parse(localStorage.settings)

    prvSettings.dimFrom = e.target.value
    localStorage.settings = JSON.stringify(prvSettings)
    setSettings(prvSettings)
  }
  const changeWgtFrom = (e) => {
    let prvSettings = JSON.parse(localStorage.settings)

    prvSettings.wgtFrom = e.target.value
    localStorage.settings = JSON.stringify(prvSettings)
    setSettings(prvSettings)
  }

  const changeOptions = (e) => {
    let prvSettings = JSON.parse(localStorage.settings)
    if (e.target.value === 'true') {
      prvSettings.showOptions = true
    } else {
      prvSettings.showOptions = false
    }
    localStorage.settings = JSON.stringify(prvSettings)
    setSettings(prvSettings)
  }

  const changeDimTo = (e) => {
    let prvSettings = JSON.parse(localStorage.settings)

    prvSettings.dimTo = e.target.value
    localStorage.settings = JSON.stringify(prvSettings)
    setSettings(prvSettings)
  }
  const changeWgtTo = (e) => {
    let prvSettings = JSON.parse(localStorage.settings)

    prvSettings.wgtTo = e.target.value
    localStorage.settings = JSON.stringify(prvSettings)
    setSettings(prvSettings)
  }

  return (
    <Container>
      <Header />
      <SettingsContainer>
        <div className='settings-form-container'>
          <div className='button-row'>
            <BackButton to='/'>
              <i className='far fa-arrow-left'></i>
              Back to kalculator
            </BackButton>
          </div>
          <div className='settings-title'>Default Settings</div>
          <div className='settings-section'>
            {settings === null ? null : (
              <>
                <div className='settings-group'>
                  <div className='settings-group-label'>
                    Convert Dimensions From
                  </div>
                  <div className='settings-group-input'>
                    <select
                      defaultValue={settings.dimFrom}
                      onChange={changeDimFrom}
                      className='settings-select'
                    >
                      <option value='in'>Inches</option>
                      <option value='mm'>Millimeters</option>
                      <option value='cm'>Centimeters</option>
                    </select>
                  </div>
                </div>
                <div className='settings-group'>
                  <div className='settings-group-label'>
                    Convert Weight From
                  </div>
                  <div className='settings-group-input'>
                    <select
                      defaultValue={settings.wgtFrom}
                      onChange={changeWgtFrom}
                      className='settings-select'
                    >
                      <option value='lb'>Lbs</option>
                      <option value='kg'>Kgs</option>
                    </select>
                  </div>
                </div>
                <div className='settings-group'>
                  <div className='settings-group-label'>
                    Display Options on start?
                  </div>
                  <div className='settings-group-input'>
                    <select
                      defaultValue={settings.showOptions ? true : false}
                      onChange={changeOptions}
                      className='settings-select'
                    >
                      <option value='true'>Yes</option>
                      <option value='false'>No</option>
                    </select>
                  </div>
                </div>
                <div className='settings-group'>
                  <div className='settings-group-label'>
                    Convert Dimensions To
                  </div>
                  <div className='settings-group-input'>
                    <select
                      defaultValue={settings.dimTo}
                      onChange={changeDimTo}
                      className='settings-select'
                    >
                      <option value='in'>Inches</option>
                      <option value='mm'>Millimeters</option>
                      <option value='cm'>Centimeters</option>
                    </select>
                  </div>
                </div>
                <div className='settings-group'>
                  <div className='settings-group-label'>Convert Weight To</div>
                  <div className='settings-group-input'>
                    <select
                      defaultValue={settings.wgtTo}
                      onChange={changeWgtTo}
                      className='settings-select'
                    >
                      <option value='lb'>Lbs</option>
                      <option value='kg'>Kgs</option>
                    </select>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </SettingsContainer>
    </Container>
  )
}

export default Settings
