import styled from 'styled-components'

const TextBox = styled.input`
  outline: none;
  border: 0;
  border: 1px solid ${(props) => props.theme.primaryColor};
  width: calc(100% - 0.5rem - 2px);
  font-size: 1.5rem;
  padding: 0.25rem;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.primaryText};
  :focus {
    border: 0;
    border-bottom: 2px solid ${(props) => props.theme.primaryColor};
    background-color: ${(props) => props.theme.background};
  }
  ::placeholder {
    color: ${(props) => props.theme.primaryText};
    font-size: 1.25rem;
    text-align: center;
  }
`

export default TextBox
