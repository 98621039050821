import '@babel/polyfill'
import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { Router, Switch, Route, HashRouter } from 'react-router-dom'
import styled from 'styled-components'

import * as theme from '#root/theme'

import Home from '#pages/Home/'
import Settings from '#pages/Settings/'

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
`

const App = () => {
  const [colorMode, setColorMode] = useState(theme.light)

  useEffect(() => {
    if (!localStorage.settings) {
      localStorage.settings = JSON.stringify({
        dimFrom: 'in',
        wgtFrom: 'lb',
        dimTo: 'in',
        wgtTo: 'lb',
        showOptions: true,
      })
    }
    let colorMode = 'light'
    if (localStorage.colorMode) {
      colorMode = localStorage.colorMode
    } else {
      localStorage.setItem('colorMode', 'light')
    }

    updateColorMode(colorMode)
  })

  const updateColorMode = async (mode) => {
    switch (mode) {
      case 'light':
        setColorMode(theme.light)
        break
      case 'dark':
        setColorMode(theme.dark)
        break

      default:
        setColorMode(theme.light)
        break
    }
  }
  return (
    <Wrapper>
      <ThemeProvider theme={colorMode}>
        <HashRouter>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/settings' component={Settings} />
            {/* <Route exact path='/settings' component={Settings} /> */}
            {/* <Route exact path='/aboutus' component={AboutUs} />
      <Route exact path='/sponsors' component={Sponsors} />
      <Route exact path='/registration' component={Registration} />
      <Route exact path='/login' component={Login} /> */}
            <Route path='*' component={Home} />
          </Switch>
        </HashRouter>
      </ThemeProvider>
    </Wrapper>
  )
}

export default App
