import React from 'react'
import styled from 'styled-components'

import Container from '#components/Container/'
import TextBox from '#elements/TextBox/'

const FormSectionA = styled.div`
  flex: 1;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.primaryLight};
`

const FormTable = styled.table`
  background-color: ${(props) => props.theme.primaryColor};
  width: 80%;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 100%;
  }
  & th {
    text-align: center;
    font-size: 1.2rem;
    color: ${(props) => props.theme.primaryLight};
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }
  & tbody {
    background-color: ${(props) => props.theme.primaryLight};
  }
  & .row-number {
    width: 30px;
  }
  & .no-border {
    border: 0;
  }
`

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.theme.primaryLight};
  margin: 1rem 0;
`

const SubmitButton = styled.button`
  outline: none;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryLight};
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 5px;
  margin: 0 auto;
  font-size: 1.4rem;
  width: 50%;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`

const FormSection = (props) => {
  const { handleTextChange, pieces, handleSubmit } = props
  const handleFocus = (event) => event.target.select()
  return (
    <FormSectionA>
      <form onSubmit={handleSubmit}>
        <FormTable>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Quantity</th>
              <th>Length</th>
              <th>Width</th>
              <th>Height</th>
              <th>Total Weight</th>
            </tr>
          </thead>
          <tbody>
            {pieces.map((p, key) => {
              return (
                <tr key={key}>
                  <td className='row-number'>{key + 1}.</td>
                  <td>
                    <TextBox
                      placeholder='Quantity'
                      type='tel'
                      name='quantity'
                      onChange={(e) => handleTextChange(e, key)}
                      defaultValue={p.quantity}
                      className='no-border'
                      autoFocus={key === 0 ? true : false}
                      required
                      onFocus={handleFocus}
                    />
                  </td>
                  <td>
                    <TextBox
                      placeholder='Length'
                      type='tel'
                      name='dimL'
                      onChange={(e) => handleTextChange(e, key)}
                      defaultValue={p.dimL}
                      className='no-border'
                      onFocus={handleFocus}
                    />
                  </td>
                  <td>
                    <TextBox
                      placeholder='Width'
                      type='tel'
                      name='dimW'
                      onChange={(e) => handleTextChange(e, key)}
                      defaultValue={p.dimW}
                      className='no-border'
                      onFocus={handleFocus}
                    />
                  </td>
                  <td>
                    <TextBox
                      placeholder='Height'
                      type='tel'
                      name='dimH'
                      onChange={(e) => handleTextChange(e, key)}
                      defaultValue={p.dimH}
                      className='no-border'
                      onFocus={handleFocus}
                    />
                  </td>
                  <td>
                    <TextBox
                      placeholder='Weight'
                      type='tel'
                      name='weight'
                      onChange={(e) => handleTextChange(e, key)}
                      defaultValue={p.weight}
                      className='no-border'
                      onFocus={handleFocus}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </FormTable>
        <ButtonRow>
          <SubmitButton type='submit' onClick={handleSubmit}>
            Submit
          </SubmitButton>
        </ButtonRow>
      </form>
    </FormSectionA>
  )
}

export default FormSection
