import React from 'react'

import styled from 'styled-components'

import TextBox from '#elements/TextBox/'

const OptionWrapper = styled.div`
  width: 100vw;
  @media (min-width: 1000px) {
    width: 80vw;
    margin: 0 auto;
    border: 1px solid ${(props) => props.theme.primaryColor};
    box-shadow: 0px 5px 3px 2px ${(props) => props.theme.primaryColor};
  }
`

const ConvTypeRow = styled.div`
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.primaryColor};
  @media (min-width: 1000px) {
    width: 80vw;
    margin: 0 auto;
    border-bottom: 1px solid ${(props) => props.theme.primaryColor};
    flex-direction: row;
  }
`

const ConvGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 0;
  @media (min-width: 1000px) {
    flex-direction: row;
    & .conv-choices {
      display: flex;
      flex-direction: row;
    }
    & .btn-conv {
      margin: 0 0.5rem;
    }
  }
  & .title-row {
    color: ${(props) => props.theme.primaryText};
    font-size: 0.9rem;
    font-family: 'Poppins', sans-serif;
    margin: 0.25rem 0.5rem;
    flex: 1;
  }
  & .btn-conv {
    outline: none;
    border: 1px solid ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryColor};
    font-size: 1.3rem;
    font-family: 'Raleway', sans-serif;
    margin: 0 0.5rem;
    cursor: pointer;
    background-color: ${(props) => props.theme.background};
  }
  & .btn-conv:hover {
    background-color: ${(props) => props.theme.primary900};
  }
  & .conv-choices {
    flex: 1;
    margin: 0 auto;
  }
  & .text-row {
    flex: 1;
    margin: 0 auto;
    text-align: center;
  }
  .active {
    outline: none;
    border: 1px solid ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryLight};
    background-color: ${(props) => props.theme.primaryColor};
    font-size: 1.3rem;
    font-family: 'Raleway', sans-serif;
    margin: 0 0.5rem;
  }
`

const HideSettings = styled.button`
  outline: none;
  background-color: ${(props) => props.theme.primaryLight};
  color: ${(props) => props.theme.primaryColor};
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 5px;
  margin: 0 auto;
  font-size: 1.4rem;
  width: 50%;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.primary900};
  }
`

const SubmitButton = styled.button`
  outline: none;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryLight};
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 5px;
  margin: 0 auto;
  font-size: 1.4rem;
  width: 50%;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`

const ResetButton = styled.button`
  outline: none;
  background-color: ${(props) => props.theme.danger};
  color: ${(props) => props.theme.primaryLight};
  border: 1px solid ${(props) => props.theme.danger};
  border-radius: 5px;
  margin: 0 auto;
  font-size: 1.4rem;
  width: 50%;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`

const Options = (props) => {
  const {
    dimFrom,
    setDimFrom,
    wgtFrom,
    setWgtFrom,
    dimTo,
    setDimTo,
    wgtTo,
    setWgtTo,
    pieces,
    handleRowChange,
  } = props

  const handleFocus = (event) => event.target.select()

  return (
    <OptionWrapper>
      <ConvTypeRow>
        <ConvGroup>
          <div className='title-row'>Convert Dimensions From</div>
          <div className='conv-choices'>
            <button
              className={dimFrom === 'cm' ? 'active' : 'btn-conv'}
              onClick={() => setDimFrom('cm')}
            >
              CM
            </button>
            <button
              className={dimFrom === 'mm' ? 'active' : 'btn-conv'}
              onClick={() => setDimFrom('mm')}
            >
              MM
            </button>
            <button
              className={dimFrom === 'in' ? 'active' : 'btn-conv'}
              onClick={() => setDimFrom('in')}
            >
              IN
            </button>
          </div>
        </ConvGroup>
        <ConvGroup>
          <div className='title-row'>Convert Weight From</div>
          <div className='conv-choices'>
            <button
              className={wgtFrom === 'kg' ? 'active' : 'btn-conv'}
              onClick={() => setWgtFrom('kg')}
            >
              KG
            </button>
            <button
              className={wgtFrom === 'lb' ? 'active' : 'btn-conv'}
              onClick={() => setWgtFrom('lb')}
            >
              LB
            </button>
          </div>
        </ConvGroup>
      </ConvTypeRow>
      {/* Start Last One */}
      <ConvTypeRow>
        <ConvGroup>
          <div className='title-row'>Convert Dimensions To</div>
          <div className='conv-choices'>
            <button
              className={dimTo === 'cm' ? 'active' : 'btn-conv'}
              onClick={() => setDimTo('cm')}
            >
              CM
            </button>
            <button
              className={dimTo === 'in' ? 'active' : 'btn-conv'}
              onClick={() => setDimTo('in')}
            >
              IN
            </button>
          </div>
        </ConvGroup>
        <ConvGroup>
          <div className='title-row'>Convert Weight To</div>
          <div className='conv-choices'>
            <button
              className={wgtTo === 'kg' ? 'active' : 'btn-conv'}
              onClick={() => setWgtTo('kg')}
            >
              KG
            </button>
            <button
              className={wgtTo === 'lb' ? 'active' : 'btn-conv'}
              onClick={() => setWgtTo('lb')}
            >
              LB
            </button>
          </div>
        </ConvGroup>
      </ConvTypeRow>
      {/* Start Second One */}
      <ConvTypeRow>
        <ConvGroup>
          <div className='title-row'>Number of Rows</div>
          <div className='text-row'>
            <TextBox
              type='number'
              style={{ width: '50%' }}
              onChange={handleRowChange}
              value={pieces?.length ? pieces.length : '1'}
              onFocus={handleFocus}
            />
          </div>
        </ConvGroup>
      </ConvTypeRow>
    </OptionWrapper>
  )
}

export default Options
