import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Icons from '#components/Icons'

const ResultSection = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.primaryLight};
  display: flex;
  flex-direction: column;
  & .result-title {
    margin: 0.25rem 0.5rem;
    color: ${(props) => props.theme.primaryColor};
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
  }
  & .result-disclaimer {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
  & .info-tile {
    margin: 0.25rem;
    color: ${(props) => props.theme.primaryDark};
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    flex: 1;
  }
  & .info-tile-row {
    width: 80%;
    margin: 0 auto;
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.primaryDark};
    flex: 1;
  }
  & .result-permit-banner {
    padding: 0.25rem 0.5rem;
    background-color: ${(props) => props.theme.warning};
    color: ${(props) => props.theme.primaryLight};
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 5px;
    flex: 0;
  }
  & .equipment-types-list {
    margin: 1rem 0;
  }
  & .equipment-types-title {
    margin: 0.5rem auto;
    width: 60vw;
    color: ${(props) => props.theme.primaryColor};
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
  }
  & .result-equipment-types {
    padding: 0.25rem;
    width: 60vw;
    margin: 0 auto;
    color: ${(props) => props.theme.primaryDark};
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${(props) => props.theme.primaryDark};
    border-left: 1px solid ${(props) => props.theme.primaryDark};
    border-right: 1px solid ${(props) => props.theme.primaryDark};
  }
  & .result-equipment-types:last-of-type {
    border-bottom: 1px solid ${(props) => props.theme.primaryDark};
  }
  & .result-equipment-types:nth-child(even) {
    background-color: hsl(268, 69%, 96%);
  }
  & .result-equipment-types div {
    padding: 0.5rem;
  }
  & .result-equipment-types div:nth-child(odd) {
    flex: 1;
    text-align: center;
  }
  & .result-equipment-types div:nth-child(even) {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 1000px) {
    & .equipment-types-title {
      width: 90vw;
    }
    & .result-equipment-types {
      width: 90vw;
      font-size: 0.9rem;
    }
    & .result-equipment-types div:nth-child(even) {
      flex-direction: row;
      justify-content: center;
    }
  }
`

const TotalTable = styled.table`
  width: 80%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.primaryText};
  @media (max-width: 1000px) {
    width: 100vw;
  }
  & td {
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.primaryDark};
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    padding: 0.25rem;
  }
`

const PieceTable = styled.table`
  display: table;
  width: 90vw;
  margin: 1rem auto;
  background-color: ${(props) => props.theme.primaryDark};
  font-size: 0.8rem;
  & thead {
    color: ${(props) => props.theme.primaryDark};
    font-family: 'Poppins', sans-serif;
    background-color: ${(props) => props.theme.primaryLight};
  }
  & th {
    padding: 0.25rem;
  }
  & tbody {
    color: ${(props) => props.theme.primaryDark};
    font-family: 'Poppins', sans-serif;
    background-color: ${(props) => props.theme.primaryLight};
  }
  & td {
    padding: 0.25rem;
    text-align: center;
  }
`

const ResultArea = (props) => {
  const [results, setResults] = useState(null)

  const {
    toggleResult,
    toggleSettings,
    startOver,
    dimTo,
    wgtTo,
    piecesResult,
  } = props

  useEffect(() => {
    if (piecesResult.hasOwnProperty('pieceInfo')) {
      setResults(piecesResult)
    }
  }, [piecesResult])

  if (results === null) {
    return <h1>Loading</h1>
  }

  if (results !== null) {
    return (
      <ResultSection>
        <div className='result-title'>
          Converted to{' '}
          {`${dimTo === 'in' ? 'inches' : 'centimeters'} and ${
            wgtTo === 'lb' ? 'pounds' : 'kilograms'
          }`}
        </div>
        <div className='result-disclaimer'>
          Responses provided are estimates only and should only be used as
          guidance.
        </div>
        <div>
          <PieceTable>
            <thead>
              <tr>
                <th>Qty</th>
                <th>Length</th>
                <th>Width</th>
                <th>Height</th>
                <th>Weight</th>
                <th>Class</th>
                <th>Sq Area</th>
                <th>Cu Area</th>
                {/* <th>Space</th> */}
              </tr>
            </thead>
            <tbody>
              {piecesResult.pieceInfo.map((pRow, key) => {
                return (
                  <tr key={key}>
                    <td>{pRow.quantity}</td>
                    <td>{pRow.dimL}</td>
                    <td>{pRow.dimW}</td>
                    <td>{pRow.dimH}</td>
                    <td>{pRow.weight}</td>
                    <td>{pRow.calculatedClass}</td>
                    <td>{pRow.sqArea}</td>
                    <td>{pRow.cuArea}</td>
                    {/* <td>{pRow.spaceOnTruck}</td> */}
                  </tr>
                )
              })}
            </tbody>
          </PieceTable>
        </div>
        <TotalTable>
          <tbody>
            <tr>
              <td>Longest Piece: {`${piecesResult.longestPiece} ${dimTo}`}</td>
              <td>{`${piecesResult.sqArea} ${
                dimTo === 'in' ? 'square feet' : 'square meters'
              }`}</td>
            </tr>
            <tr>
              <td>Widest Piece: {`${piecesResult.widestPiece} ${dimTo}`}</td>
              <td>
                {`${piecesResult.cuArea} ${
                  dimTo === 'in' ? 'cubic feet' : 'cubic meters'
                }`}
              </td>
            </tr>
            <tr>
              <td>Tallest Piece: {`${piecesResult.tallestPiece} ${dimTo}`}</td>
              <td>
                {`${piecesResult.density} ${
                  dimTo === 'in' ? 'lb/cf' : 'kg/cm'
                }`}
              </td>
            </tr>
            <tr>
              <td>
                Heaviest Piece: {`${piecesResult.heaviestPiece} ${wgtTo}`}
              </td>
              <td>Calculated Class: {`${piecesResult.calculatedClass}`}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            {/* <tr>
              <td>Space on Truck: {`${piecesResult.spaceOnTruck} feet`}</td>
              <td>Truck Feet: {`${piecesResult.truckFeet} feet`}</td>
            </tr> */}
            <tr>
              <td>Total Quantity: {`${piecesResult.totalQuantity}`}</td>
              <td>Total Weight: {`${piecesResult.totalWeight} ${wgtTo}`}</td>
            </tr>
          </tbody>
        </TotalTable>

        <div className='experimental-stuff'>
          <div className='experimental-group'>
            <div className='experimental-title'>
              {dimTo === 'in' ? 'Feet of Truck' : 'Meters of Truck: '}
              <span className='danger-note-star'>*</span>
            </div>
            <div className='experimental-item'>
              {Math.ceil(piecesResult.spaceOnTruck)}
            </div>
          </div>
          <div className='experimental-notes'>
            <span className='danger-note-star'>*</span>This section is currently
            being tested and may not be accurate in all instances.
          </div>
        </div>

        <div className='equipment-types-list'>
          <div className='equipment-types-title'>
            {`Possible Shipping Methods`}
          </div>
          {piecesResult.possibleEq.length === 0
            ? null
            : piecesResult.possibleEq.map((eq, key) => {
                return (
                  <div key={key} className='result-equipment-types'>
                    <div>
                      <Icons icon={eq.icon} />
                    </div>
                    <div>
                      <div style={{ flex: 2 }}>{eq.label}</div>
                      {eq.reqPermit ? (
                        <span className='result-permit-banner'>Permit</span>
                      ) : null}
                    </div>
                    <div>{eq.numEq} truck(s) may be required</div>
                  </div>
                )
              })}
        </div>
        {/* <div>{JSON.stringify(piecesResult)}</div> */}
      </ResultSection>
    )
  }
}

export default ResultArea
